import { getAccount } from "@/api/account-service";
import { getOnboardingSettings, getTeamSettings } from "@/api/platform-settings-service";
import { getProfile } from "@/api/profile-service";
import { getUser } from "@/api/user-service";
import { listCourses } from "@/api/course-service";
import createPlugin from "logrocket-vuex";
import Learnlink from "@learnlink/interfaces";
import LogRocket from "logrocket";
import Vue from "vue";
import Vuex from "vuex";

const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getDefaultState = () => {
  return {
    account: {} as Learnlink.Account.default,
    courses: [] as Learnlink.Course.default[],
    platformSettings: {
      onboarding: {} as Learnlink.PlatformSettings.Onboarding,
      teams: {} as Learnlink.PlatformSettings.Teams,
    },
    profile: {} as Learnlink.Profile.default,
    ui: {
      alert: {
        active: false,
        color: "",
        message: "",
      },
    },
    user: {} as Learnlink.User.default,
  };
};

const store = new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    emptyState(state): void {
      Object.assign(state, getDefaultState());
    },
    mountAccount(state, account: Learnlink.Account.default): void {
      state.account = account;
    },
    mountCourses(state, courses: Learnlink.Course.default[]): void {
      state.courses = courses;
    },
    mountOnboardingSettings(state, onboardingSettings: Learnlink.PlatformSettings.Onboarding): void {
      state.platformSettings.onboarding = onboardingSettings;
    },
    mountProfile(state, profile: Learnlink.Profile.default): void {
      state.profile = profile;
    },
    mountTeamSettings(state, teamSettings: Learnlink.PlatformSettings.Teams): void {
      state.platformSettings.teams = teamSettings;
    },
    mountUser(state, user: Learnlink.User.default): void {
      state.user = user;
    },
    displayAlert(state, payload): void {
      state.ui.alert = {
        ...state.ui.alert,
        ...payload,
        active: true,
      };
    },
  },
  actions: {
    displayAlert: ({ commit }, payload): void => {
      commit("displayAlert", payload);
    },
    emptyState: ({ commit }): void => {
      commit("emptyState");
    },
    async signIn({ commit }, uid): Promise<void> {
      const account = await getAccount(uid);
      commit("mountAccount", account);

      const onboardingSettings = await getOnboardingSettings();
      commit("mountOnboardingSettings", onboardingSettings);

      const teamSettings = await getTeamSettings();
      commit("mountTeamSettings", teamSettings);

      const profile = await getProfile(uid);
      commit("mountProfile", profile);

      const user = await getUser(uid);
      commit("mountUser", user);
    },
    async mountCourses({ commit }): Promise<void> {
      const courses = await listCourses();
      commit("mountCourses", courses);
    },
  },
  plugins: [logrocketPlugin],
});

export default store;
