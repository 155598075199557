import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/platform-settings";

export async function getOnboardingSettings(): Promise<Learnlink.PlatformSettings.Onboarding> {
  const res = await axios.get<Learnlink.PlatformSettings.Onboarding>(
    `${ENDPOINT}/onboarding`
  );
  return res.data;
}

export async function getTeamSettings(): Promise<Learnlink.PlatformSettings.Teams> {
  const res = await axios.get<Learnlink.PlatformSettings.Teams>(
    `${ENDPOINT}/teams`
  );
  return res.data;
}

export async function updateOnboardingSettings(settings: Learnlink.PlatformSettings.Onboarding):
Promise<Learnlink.PlatformSettings.Onboarding> {
  const res = await axios.put<Learnlink.PlatformSettings.Onboarding>(
    `${ENDPOINT}/onboarding`,
    settings
  );
  return res.data;
}
