































































































































































































































import { createDiplomasForTeam } from "@/api/team-service";
import { capitalize } from "@/helpers/stringUtils";
import { computed, defineComponent, inject, onMounted, PropType, reactive } from "@vue/composition-api";
import * as TeamService from "@/api/team-service";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";
import store from "@/store/store";

export default defineComponent({
  name: "EditTeamCard",
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = reactive({
      birthYears: [] as number[],
      city: "",
      courseID: "",
      currentCourse: {} as Learnlink.Course.default,
      internalNote: "",
      loading: false,
      location: "",
      locations: [{
        ID: "",
        address: "",
        mapURL: "",
        title: "(Ikke valgt)",
      }].concat(store.state.platformSettings.teams.locations),
      salary: 0,
      sellerNote: "",
      size: 0,
      title: "",
    });

    const cities = ["Oslo", "Bergen", "Trondheim", "Stavanger", "Tromsø", "(Ikke valgt)"];

    const mount = inject<() => Promise<void>>("mount");

    onMounted(() => {
      vm.birthYears = props.team.birthYears;
      vm.city = props.team.city;
      vm.courseID = props.team.courseID;
      vm.currentCourse = store.state.courses.filter((course) => course.ID === props.team.courseID)[0];
      vm.internalNote = props.team.internalNote;
      vm.location = props.team.location;
      vm.salary = props.team.salary / 100;
      vm.sellerNote = props.team.sellerNote;
      vm.size = props.team.size;
      vm.title = props.team.title;
    });

    async function activate(): Promise<void> {
      vm.loading = true;

      await TeamService.activateTeam(props.team.ID);

      await store.dispatch("displayAlert", {
        color: "success",
        message: "Aktivt! 🥳",
      });

      mount && await mount();

      vm.loading = false;
    }

    async function deactivate(): Promise<void> {
      vm.loading = true;

      await TeamService.deactivateTeam(props.team.ID);

      await store.dispatch("displayAlert", {
        color: "success",
        message: "Deaktivert",
      });

      mount && await mount();

      vm.loading = false;
    }

    async function deleteTeam(): Promise<void> {
      vm.loading = true;

      try {
        await TeamService.deleteTeam(props.team.ID);
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Teamet ble slettet! 😈",
        });
        emit("deleted");
      }
      catch (e) {
        handleError(e);
      }
      vm.loading = false;
    }

    async function completeTeam(): Promise<void> {
      vm.loading = true;

      try {
        await TeamService.completeTeam(props.team.ID);
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Teamet er fullført! 🥲",
        });
        emit("completed");
      }
      catch (e) {
        handleError(e);
      }
      vm.loading = false;
    }

    async function updateTeam() {
      vm.loading = true;

      let cityChoice = vm.city ?? "";
      let locationChoice = vm.location ?? "";

      if (cityChoice === "(Ikke valgt)" || cityChoice === "") {
        cityChoice = "";
        locationChoice = "";
        vm.location = "(Ikke valgt)"; // reset city
      }
      else {
        cityChoice = capitalize(cityChoice);
      }

      if ((locationChoice === "(Ikke valgt)" || locationChoice === "") && (cityChoice !== "(Ikke valgt)" && cityChoice !== "")) {
        vm.loading = false;
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Du mangler å velge lokasjon",
        });
        return;
      }

      try {
        const updateObject: Learnlink.Team.Update = {
          ID: props.team.ID,
          birthYears: vm.birthYears,
          city: cityChoice,
          courseID: vm.courseID,
          internalNote: vm.internalNote,
          location: locationChoice,
          salary: vm.salary * 100,
          sellerNote: vm.sellerNote,
          size: vm.size,
          title: vm.title,
        };

        await TeamService.updateTeam(updateObject);

        await store.dispatch("displayAlert", {
          color: "success",
          message: "Team oppdatert! 🥳",
        });

        mount && await mount();
      }
      catch (e) {
        handleError(e);
      }

      vm.loading = false;
    }

    const courses = store.state.courses.map((course) => {
      return {
        readableTitle: `${course.title} (${course.weeks} ukers kurs)`,
        ...course,
      };
    });

    const hasTeamMembers = computed(() => {
      return ((props.team.sellers?.length || 0) + (props.team.parents?.length || 0) + (props.team.students?.length || 0)) > 0;
    });

    const notFinishedAllSessions = computed(() => {
      const now = Moment().unix();
      return props.team?.teamSessions?.some(session => session.startTime > now);
    });

    const isCalendarCourse = computed(() => {
      return props.team.course?.location === "calendar";
    });

    return {
      activate,
      completeTeam,
      courses,
      createDiplomasForTeam,
      deactivate,
      deleteTeam,
      hasTeamMembers,
      mount,
      notFinishedAllSessions,
      updateTeam,
      vm,
      isCalendarCourse,
      cities
    };
  },
});
