











































































import { createStudent, getStudent } from "@/api/customer-service";
import { updateAccount } from "@/api/account-service";
import { updateProfile } from "@/api/profile-service";
import { updateEmail } from "@/api/user-service";
import BirthdatePicker from "@/components/BirthdatePicker.vue";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";
import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "EditStudentForm",
  components: { BirthdatePicker },
  props: {
    customer: {
      type: Object as PropType<Learnlink.Customer.default>,
      default: () => ({} as Learnlink.Customer.default),
    },
    edit: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    studentUID: {
      type: String as PropType<string>,
      default: "",
    },
  },
  setup(props, { emit }) {
    const vm = reactive({
      existingStudent: {} as Learnlink.Student.Student,
      loading: false,
      student: {
        birthdate: "",
        email: "",
        firstName: "",
        lastName: "",
        parentUID: props.customer?.user?.uid || undefined,
        phone: "",
      } as Learnlink.Student.Create,
      students: props.customer?.user?.students || [],
    });

    onMounted(async () => {
      vm.loading = true;
      try {
        if (props.edit && props.studentUID) {
          vm.existingStudent = await getStudent(props.studentUID);
          vm.student.birthdate = vm.existingStudent.account?.birthdate || "";
          vm.student.email = vm.existingStudent.account?.email || "";
          vm.student.phone = vm.existingStudent.account?.phone || "";
          vm.student.firstName = vm.existingStudent.profile?.firstName || "";
          vm.student.lastName = vm.existingStudent.profile?.lastName || "";
        }
      }
      catch (e) {
        handleError(e);
      }
      vm.loading = false;
    });

    async function updateStudent() {
      await Promise.all([
        updateProfile({
          uid: props.studentUID,
          firstName: vm.student.firstName,
          lastName: vm.student.lastName,
        }),
        updateAccount({
          uid: props.studentUID,
          birthdate: vm.student.birthdate,
          phone: vm.student.phone || undefined,
        }),
      ]);

      if (vm.student.email !== vm.existingStudent.account?.email) {
        await updateEmail(props.studentUID, vm.student.email);
      }
      await store.dispatch("displayAlert", {
        title: "🥳",
        message: "Eleven ble oppdatert.",
        color: "success",
      });
    }

    async function createNewStudent() {
      await createStudent({
        email: vm.student.email,
        firstName: vm.student.firstName,
        lastName: vm.student.lastName,
        parentUID: props.customer.user.uid,
        phone: vm.student.phone || undefined,
      });
      await store.dispatch("displayAlert", {
        title: "🥳",
        message: "Eleven ble opprettet.",
        color: "success",
      });
    }

    async function submit() {
      vm.loading = true;
      try {
        if (props.edit && props.studentUID) await updateStudent();
        else await createNewStudent();
        emit("success");
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          title: "😭",
          message: "Noe gikk galt.",
          color: "error",
        });
        handleError(e);
      }
      vm.loading = false;
    }

    return {
      submit,
      vm,
    };
  },
});
