





































import { defineComponent, onMounted, PropType, reactive, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "BirthdatePicker",
  props: {
    value: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    const vm = reactive({
      day: "",
      days: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
      month: "",
      months: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      year: "",
      years: ["2006", "2007", "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016"],
    });

    watchEffect(() => {
      const formatted = `${formatNumber(vm.year)}-${formatNumber(vm.month)}-${formatNumber(vm.day)}`;
      emit("input", formatted);
    });

    onMounted(() => {
      const propDay = props.value?.split("-")[2];
      const propMonth = props.value?.split("-")[1];

      const propYear = props.value?.split("-")[0];
      vm.day = propDay ? stripZero(propDay) : "";
      vm.month = propMonth ? stripZero(propMonth) : "";
      vm.year = propYear || "";
    });

    function formatNumber(number: string): string {
      return parseInt(number) < 10 ? `0${number}` : number;
    }

    function stripZero(numberString: string): string {
      return numberString.charAt(0) === "0" ? numberString.charAt(1) : numberString;
    }

    return {
      vm,
      _value,
    };
  },
});
