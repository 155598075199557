






















































































































































import { copyToClipboard } from "@/helpers/copyToClipboard";
import { defineComponent, PropType, provide, reactive } from "@vue/composition-api";
import handleError, { sanitizeError } from "@/helpers/errors";
import * as TeamService from "@/api/team-service";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";
import TeamUserList from "@/components/teams/TeamUserList.vue";

export default defineComponent({
  name: "EditTeamMembers",
  components: { TeamUserList },
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = reactive({
      loading: false,
      parentUID: "",
      sellerUID: "",
      studentUID: "",
    });

    async function addUser(userType: Learnlink.Type.Role, uid: string) {
      vm.loading = true;

      try {
        if (!uid) {
          return;
        }
        else if (props.team.sellers?.concat(
          props.team.parents ? props.team.parents : [],
        ).concat(props.team.students ? props.team.students : []).includes(uid)) {
          await store.dispatch("displayAlert", {
            color: "error",
            message: "Brukeren er allerede en del av teamet.",
          });
        }
        else if (userType === "seller" && !props.team.teamSessions.length) {
          await store.dispatch("displayAlert", {
            color: "error",
            message: "Kan ikke legge til Pro før kursdager og -tider er satt opp.",
          });
        }
        else {
          await TeamService.addUser(userType, props.team.ID, uid);
          vm.parentUID = "";
          vm.sellerUID = "";
          vm.studentUID = "";
          await store.dispatch("displayAlert", {
            color: "success",
            message: "Lagt til! 🥳",
          });
          emit("success");
        }
      }
      catch (e) {
        if (sanitizeError(e).message.includes("Unknown member")) {
          handleError(e);
        }
        else {
          await store.dispatch("displayAlert", {
            color: "error",
            message: sanitizeError(e).message,
          });
        }
      }

      vm.loading = false;
    }

    async function churnUser(userType: Learnlink.Type.Role, uid: string) {
      vm.loading = true;

      if (userType === "parent") {
        try {
          await TeamService.churnUser(userType, props.team.ID, uid);
          await store.dispatch("displayAlert", {
            color: "success",
            message: "Fjernet! Husk at du også må fjerne eventuelle barn.",
          });
          emit("success");
        }
        catch (e) {
          await store.dispatch("displayAlert", {
            color: "error",
            message: sanitizeError(e).message,
          });
        }
      }
      else {
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Kan ikke churne andre enn foreldre! 🥳",
        });
      }

      vm.loading = false;
    }

    async function removeUser(userType: Learnlink.Type.Role, uid: string) {
      vm.loading = true;

      try {
        await TeamService.removeUser(userType, props.team.ID, uid);
        if (userType === "parent") {
          await store.dispatch("displayAlert", {
            color: "success",
            message: "Fjernet! Husk at du også må fjerne eventuelle barn.",
          });
        }
        else {
          await store.dispatch("displayAlert", {
            color: "success",
            message: "Fjernet! 🥳",
          });
        }
        emit("success");
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: sanitizeError(e).message,
        });
      }

      vm.loading = false;
    }

    provide("removeUserFromTeam", removeUser);
    provide("churnUserFromTeam", churnUser);

    return {
      addUser,
      copyToClipboard,
      vm,
    };
  },
});
