







































import { addUserUIDtoLogRocket } from "@/services/logrocket";
import { auth } from "@/services/firebase";
import { defineComponent, provide, reactive, watch } from "@vue/composition-api";
import { environment, sentry } from "@/configuration";
import * as Integrations from "@sentry/integrations";
import * as version from "../version.json";
import EditCustomerDialog from "@/components/customers/EditCustomerDialog.vue";
import TeamDialog from "@/components/teams/TeamDialog.vue";
import LogRocket from "logrocket";
import MainMenu from "@/components/layout/MainMenu.vue";
import store from "@/store/store";
import Vue from "vue";

export default defineComponent({
  components: { MainMenu, TeamDialog, EditCustomerDialog },
  setup() {
    const vm = reactive({
      customerUID: "",
      editCustomerDialogVisible: false,
      teamDialogVisible: false,
      teamID: "",
    });

    watch(() => vm.teamDialogVisible, () => {
      if (!vm.teamDialogVisible) vm.teamID = "";
    });

    if (window.location.hostname !== "localhost") {
      sentry.init({
        dsn: "https://f2a7b9deae1f46b8b4196a49ba8b4d86@sentry.io/1446660",
        environment: environment?.substring(10) || "",
        release: version.version,
        integrations: [
          new Integrations.Vue({
            Vue,
            attachProps: true,
          }),
        ],
      });
      LogRocket.init("i77qy0/adminlearnlinkno");
      LogRocket.getSessionURL(sessionURL => {
        sentry.configureScope(scope => {
          scope.setExtra("sessionURL", sessionURL);
        });
      });
    }

    auth.onAuthStateChanged(async firebaseUser => {
      if (firebaseUser) {
        if (window.location.hostname !== "localhost") {
          sentry.configureScope(scope => {
            scope.setUser({
              id: firebaseUser.uid,
            });
          });
        }
        addUserUIDtoLogRocket(firebaseUser.uid);
        await Promise.all([
          store.dispatch("signIn", firebaseUser.uid),
          store.dispatch("mountCourses"),
        ]);
        if (store.state.user.uid && !store.state.user.employee) {
          store.dispatch("displayAlert", {
            message: "Du har ikke tilgang her",
            color: "error",
          });
        }
      }
    });

    function openEditCustomerDialog(customerUID: string) {
      vm.editCustomerDialogVisible = true;
      vm.customerUID = customerUID;
    }

    function openTeamDialog(teamID: string) {
      if (vm.teamID === teamID) {
        store.dispatch("displayAlert", {
          message: "Teamet er allerede åpent 😉",
          color: "warning",
        });
      }
      else {
        vm.teamDialogVisible = true;
        vm.teamID = teamID;
      }
    }

    provide("openEditCustomerDialog", openEditCustomerDialog);
    provide("openTeamDialog", openTeamDialog);

    return {
      store,
      vm,
    };
  },
});
